/* @import "./src/pages/analytics.css";
@import "./src/pages/login.css";
@import "./src/pages/profile.css";
@import "./src/pages/projectsSettings.css";
@import "./src/pages/qrCodeList.css";
@import "./src/components/navBar.css";
@import "./src/components/projectsOverview.css";
@import "./src/components/qrCodeGenerator.css"; */

@font-face {
  font-family: header;
  src: url(./assets/fonts/Black_Ops_One/BlackOpsOne-Regular.ttf);
}
@font-face {
  font-family: regular;
  src: url(./assets/fonts/Old_Standard_TT/OldStandardTT-Bold.ttf);
}
h1,
h2,
h3,
h4,
h5,
p {
  font-family: black-ops !important;
}

body {
  background-color: #f0f0f2;
  margin: 0;
  min-height: 100vh;
  max-width: 1300px;
  margin: 0 auto;
}
html {
  background-color: rgb(0, 0, 0);
}
main {
  margin: 0 auto;
}

a {
  color: black;
  text-decoration: none;
  font-size: 30px;
  margin: 10px;
}

button {
  color: #000;
  background-color: #999;
  margin-top: 20px;
}
projectsetting-page {
  display: block;
  height: 100%;
}

/* dialog */
#modal {
  position: absolute;
  top: 50%;
  z-index: 20;
  /* transform: translate(-100%, 50%); */
  background-color: #d9d9d9;
  border: none;
  border-radius: 5px;
}
.buttons {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

#modal button {
  height: 30px;
  background-color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 40px;
}
#confirm {
  background-color: red;
}

#userModal {
  position: absolute;
  top: 67px;
  left: 880px;
  width: 350px;
  background-color: #d9d9d9;
  z-index: 30;
  border-radius: 10px;
}
#closeDialog {
  position: absolute;
  right: 10px;
  border: none;
  background-color: transparent;
  margin: 0;
  transform: scale(1.5);
}
.imageWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 0 20px 0;
}
#userImage {
  border-radius: 50%;
  height: 80px;
  width: 80px;
  margin: 0 auto;
  object-fit: cover;
}
.userButtons {
  display: flex;
  flex-direction: column;
  width: 100%;
}
#profileButton,
#signOutButton {
  height: 40px;
  margin: 0;
  border: none;
}
#profileButton {
  border-radius: 10px 10px 0 0;
}
#signOutButton {
  background-color: rgb(240, 140, 140);
  border-radius: 0 0 10px 10px;
}

/* snackbar */
#snackbar {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 11;
  left: 50%;
  bottom: 30px;
}

.show {
  visibility: visible !important;
  -webkit-animation: fadein 0.5s;
  animation: fadein 0.5s;
}

@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@media screen and (max-width: 1300px) {
  #userModal {
    left: calc(100% - 400px);
  }
}

#deleteProject {
  z-index: 11;
  background-color: #d9d9d9;
  border: none;
  border-radius: 5px;
  width: 20%;
}
#deleteProject button {
  cursor: pointer;
  background-color: #fff;
  border: none;
  border-radius: 5px;
  width: 40px;
  height: 30px;
}
